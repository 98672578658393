import { mapActions } from 'vuex'

const mixin = {

  created: function() {
    console.debug(`Homepage action called.`)
  },

  methods: {
    ...mapActions(['getHomepageData']),

    
  }
}

export default mixin