<template>
    <div class="w-screen md:h-screen h-screen-85 relative flex overflow-hidden">
        
            <!-- begin::image -->
            <transition mode="out-in" name="slide-image2">

                <img v-if="district.id == 9998 || district.id == 9999" class="h-screen w-full object-cover absolute hidden md:block" :key="district.id" 
                    v-lazy="district.cover"
                    alt="city">

                <img v-else class="h-screen w-full object-cover absolute hidden md:block" :key="district.id" 
                    v-lazy="$mediaFile('districts', district.id , district.cover)"
                    alt="city">
                    
            </transition>
            <!-- begin::end image -->

            <!-- begin::overlay -->
            <transition name="fade">
                <div v-if="show" class="absolute overlay-districts h-full w-full hidden md:block">
                </div>
            </transition>
            <!-- end::overlay-->

            <!-- begin:: districts text -->
            <transition mode="out-in" name="slow-slide-fade"> 
                <DistrictsInfo :propertiesQTY="totalProperties" :key="district.id" :district="district" v-if="district"
                    class="mx-auto hidden md:flex md:h-full lg:h-5/6 z-20 relative" />
            </transition>
            <!-- end:: districts text -->

            <!-- begin:: next districts -->
            <transition mode="out-in" name="slow-slide-fade">
                <NextDistrict 
                    @click.native="toNextDistrict()" 
                    :key="nextDistrict.id" 
                    :district="nextDistrict" 
                    v-if="nextDistrict != null" 
                    :numberProperties="nextDistrictTotal"
                    class="inset-y-0 my-auto w-frame glass-effect h-frame right-14 z-20 hidden lg:block" />
            </transition>
            <!-- end:: next districts -->

            <transition mode="out-in" name="slide-fade">
                <div class="w-full flex justify-center  block md:hidden">
                    <NextDistrict 
                        @click.native="toNextDistrict()" 
                        :key="nextDistrict.id" 
                        :district="nextDistrict" 
                        v-if="nextDistrict != null" 
                        :numberProperties="nextDistrictTotal"
                        class="inset-y-0 my-auto w-3/4 mx-0 h-full bg-black z-20" />
                </div>
            </transition>

            <!-- begin:: districts properties -->
            <transition mode="out-in" name="slow-slide-fade">
                <DistrictsProperties 
                    class="z-20 hidden lg:flex" 
                    :district="district" 
                    :key="dPropertiesKey" 
                    ref="DistrictsPropertiesReference"
                    v-if="false"
                    :propertiesList="getPropertiesComputed" 
                />
            </transition>
            <!-- end:: districts properties -->

            <!-- begin::navigation district -->
            <div class="absolute mt-auto left-2 md:left-3 h-full z-30 flex items-center text-black md:text-white">
                <button @click="toPreviousDistrict()">
                    <inline-svg class="transform rotate-180 fill-current w-4 h-4 md:w-7 md:h-7 lg:w-9 lg:h-9"  src="/svg/arrow/arrow.svg" />
                </button>
            </div>
            <div class="absolute right-2 md:right-3 mt-auto h-full z-30  flex items-center text-black md:text-white">
                <button @click="toNextDistrict()">
                    <inline-svg class="fill-current w-4 h-4 md:w-7 md:h-7 lg:w-9 lg:h-9" src="/svg/arrow/arrow.svg" />
                </button>
            </div>
            <!-- end::navigation district -->
      
    </div>
</template>

<script>
    import DistrictsInfo from "@/components/districts/DistrictsInfo";
    import NextDistrict from "@/components/districts/NextDistrict";
    import DistrictsProperties from "@/components/districts/DistrictsProperties";

    export default {
        name: "Districts",
        components: {
            DistrictsInfo,
            NextDistrict,
            DistrictsProperties
        },
        props: ['items', 'propertiesList'],
        data() {
            return {
                currentDistrict: 0,
                district: null,
                districtsList: null,
                nextDistrict: null,
                previousDistrict: null,
                show: true,
                dPropertiesKey: 0 + 'z',
                propertiesListShow: false,
                totalProperties: 0,
                nextDistrictTotal: 0
            }
        },
        methods: {
            toNextDistrict() {
                this.show = false;
                var self = this;

                if (this.currentDistrict === this.items.length - 1) {
                    this.currentDistrict = 0;
                    this.district = this.items[this.currentDistrict];

                } else {
                    this.currentDistrict = this.currentDistrict + 1;
                    this.district = this.items[this.currentDistrict];
                }
                if (this.currentDistrict === this.items.length - 1) {
                    this.nextDistrict = this.items[0];
                    this.previousDistrict = this.items[0]
                } else {
                    this.nextDistrict = this.items[this.currentDistrict + 1];
                    this.previousDistrict = this.items[this.currentDistrict - 1]
                }
                setTimeout(function () {
                    self.show = true;
                }, 2000);

                this.dPropertiesKey++


                let actualName = this.district.name
                this.nextDistrictTotal = 0
                this.totalProperties = 0
                let nextName = this.nextDistrict.name
                let parent = this
                this.propertiesList.forEach(element => {
                    if(element.information.location.district.en == actualName || element.information.location.district.pt == actualName)
                        parent.totalProperties++

                    if(element.information.location.district.en == nextName || element.information.location.district.pt == nextName)
                        parent.nextDistrictTotal++
                });


                
                this.$refs.DistrictsPropertiesReference.goToFirstPage()
            },

            toPreviousDistrict() {
                this.show = false;
                var self = this;
                if (this.currentDistrict === 0) {
                    this.currentDistrict = this.items.length - 1;
                    this.nextDistrict = this.items[0];
                    this.previousDistrict = this.items[0]
                    this.district = this.items[this.currentDistrict];
                } else {
                    this.currentDistrict = this.currentDistrict - 1;
                    this.nextDistrict = this.items[this.currentDistrict + 1];
                    this.previousDistrict = this.items[this.currentDistrict - 1]
                    this.district = this.items[this.currentDistrict];
                }
                setTimeout(function () {
                    self.show = true;
                }, 2000);

                this.dPropertiesKey++

                let actualName = this.district.name
                this.nextDistrictTotal = 0
                this.totalProperties = 0
                let nextName = this.nextDistrict.name
                let parent = this
                this.propertiesList.forEach(element => {
                    if(element.information.location.district.en == actualName || element.information.location.district.pt == actualName)
                        parent.totalProperties++

                    if(element.information.location.district.en == nextName || element.information.location.district.pt == nextName)
                        parent.nextDistrictTotal++
                });


                this.$refs.DistrictsPropertiesReference.goToFirstPage()
            }
            
        },
        created() {
            let iLisbonPos = 0
            let i = 0
            let sortedArray = this.items.sort((a, b) => a.sort_id - b.sort_id);
            this.items = sortedArray
            
            this.items.forEach(function(key){
                if(key.name == 'Lisboa' || key.name == 'Lisbon')
                    iLisbonPos = i
                
                i++                
            })
            
            this.currentDistrict = iLisbonPos
            this.district = this.items[this.currentDistrict];
            this.nextDistrict = this.items[this.currentDistrict + 1];
            this.previousDistrict = this.items[this.items.length-1];

            let actualName = this.district.name
            this.nextDistrictTotal = 0
            this.totalProperties = 0
            let nextName = this.nextDistrict.name
            let parent = this
            this.propertiesList.forEach(element => {
                if(element.information.location.district.en == actualName || element.information.location.district.pt == actualName)
                    parent.totalProperties++

                if(element.information.location.district.en == nextName || element.information.location.district.pt == nextName)
                    parent.nextDistrictTotal++
            });
    

            


        },
        watch:{
            propertiesList(){
                let actualName = this.district.name
                let nextName = this.nextDistrict.name
                let parent = this
                
                if(this.propertiesList != undefined){
                    this.propertiesList.forEach(element => {
                        if(element.information.location.district.en == actualName || element.information.location.district.pt == actualName)
                            parent.totalProperties++

                        if(element.information.location.district.en == nextName || element.information.location.district.pt == nextName)
                            parent.nextDistrictTotal++
                    });
                }

            }
        },
        computed:{
            getPropertiesComputed(){
                let finalPropList = []
                // Array para saber se uma propriedade de um condominio já foi listada
                let condominiumsUsed = []
                let districtName = this.district.name
                let maxListPerItem = 6

                if(this.propertiesList == null)
                    return null

                // 6 propriedades de Quinta de vinhos
                // FEATURE ID 28 VINHAS
                if(districtName == "Quinta de vinhos" || districtName == "Vineyards"){
                    this.propertiesList.forEach(function(key){
                        let featuresArray = key.features_id.split(',')
                        
                        // Se o condominio for null, então não precisamos de registar
                        if(key.condominium_id == null){
                            if(featuresArray.includes('28')){
                                finalPropList.push(key)
                            }
                        }else{
                            // Se tiver condominio, vamos verificar se pertence às vinhas
                            if(featuresArray.includes('28')){
                                // Se pertencer as vinhas, então interessa. Vamos verificar se já temos alguma propriedade deste condominio
                                if(!condominiumsUsed.includes(key.condominium_id)){
                                    // Se não tivermos, vamos guardar a propriedade e o condominio já usado
                                    finalPropList.push(key)
                                    condominiumsUsed.push(key.condominium_id)
                                }
                            }
                        }

                        // Vamos sair do ciclo se já tivermos 6 propriedades
                        if(finalPropList.length == maxListPerItem)
                            return
                    })

                    

                    return finalPropList
                }


                // 6 propriedades de Praia
                // FEATURE ID 6 BEACHFRONT
                if(districtName == "Praia" || districtName == "Beachfront"){
                    this.propertiesList.forEach(function(key){
                        let featuresArray = key.features_id.split(',')

                        // Se o condominio for null, então não precisamos de registar
                        if(key.condominium_id == null){
                            if(featuresArray.includes('6')){
                                finalPropList.push(key)
                            }
                        }else{
                            // Se tiver condominio, vamos verificar se pertence a praias
                            if(featuresArray.includes('6')){
                                // Se pertencer as praias, então interessa. Vamos verificar se já temos alguma propriedade deste condominio
                                if(!condominiumsUsed.includes(key.condominium_id)){
                                    // Se não tivermos, vamos guardar a propriedade e o condominio já usado
                                    finalPropList.push(key)
                                    condominiumsUsed.push(key.condominium_id)
                                }
                            }
                        }

                        // Vamos sair do ciclo se já tivermos 6 propriedades
                        if(finalPropList.length == maxListPerItem)
                            return
                    })

                    return finalPropList
                }

                // 6 propriedades de cada Cidade
                let parent = this
                this.propertiesList.forEach(function(key){
                    if(parent.$processLanguage(key.location) == districtName){       
                           
                        // Se o condominio for null, então não precisamos de registar
                        if(key.condominium_id == null)
                            finalPropList.push(key)
                        else{
                            // se o condominio não for null, vamos verificar se já temos alguma propriedade deste condominio
                            if(!condominiumsUsed.includes(key.condominium_id)){
                                // Se não tivermos, vamos guardar a propriedade e o condominio já usado
                                finalPropList.push(key)
                                condominiumsUsed.push(key.condominium_id)
                            }
                        }
                    }

                    // Vamos sair do ciclo se já tivermos 6 propriedades
                    if(finalPropList.length == maxListPerItem)
                        return finalPropList
                })
                
                return finalPropList
            }
        }
        
    }
</script>


