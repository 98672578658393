<template>
    <div>
        <div class="relative container">
            <!-- image -->
            <div
                class="overflow-hidden rounded-lg hover:border-bronze border-2 transition-all duration-300 border-transparent lg:rounded-2xl new-developments w-full relative"
                >
                
                <img :key="reloadKeyImage" class="hover:scale-110 origin-center transition-all transform duration-500 ease-in-out w-full h-full object-cover"
                    v-lazy="img" @error="errorImage">
            </div>
            <div class="rounded-lg lg:rounded-2xl listingDetail">
              <span>
                <p>
                  <inline-svg class="fill-current w-4 h-4 md:w-4 md:h-4 lg:w-7 lg:h-7" src="/svg/newDevelopments/area.svg" />
                </p>
                <p class="font-medium leading-none text-baselg">{{grossArea}} {{ $t('propertyCard.area') }}</p>
              </span>
              <span>
                <p>
                  <inline-svg class="fill-current w-4 h-4 md:w-4 md:h-4 lg:w-7 lg:h-7" src="/svg/houseDetails/rooms.svg" />
                </p>
                <p class="font-medium leading-none text-baselg">{{ bedrooms }} {{ $t('propertyCard.beds') }}</p>
              </span>
              <span>
                <p>
                  <inline-svg class="fill-current w-4 h-4 md:w-4 md:h-4 lg:w-7 lg:h-7" src="/svg/houseDetails/garage.svg" />
                </p>
                <p class="font-medium leading-none text-baselg">{{ parking }} {{ '' }}</p>
              </span>
            </div>
            <!-- tag -->
            <div class="absolute w-full h-full px-2 py-3 flex items-end justify-center price">
                <!-- price -->
                <p class="px-2 z-10 text-darkgray text-base font-semibold py-1 rounded-md md:rounded-lg">
                    {{getLabel}}
                    <span :class="price == this.$t('price_under_request') ? 'font-bold' : 'font-normal'">{{price}}</span>
                </p>
            </div>
        </div>
        <div class="mt-2">
            <p class="truncate font-bold text-baselg">{{title}}</p>
            <p class="truncate text-base">{{location}}</p>
        </div>
    </div>
</template>
<style scoped>
.price {
  top:0;
}
.price > p {
  color: black;
  background: rgba(255,255,255,0.8);
}
.container {
  cursor: pointer;
}
.container:hover .price > p{
  color: white;
  background: rgba(255,255,255,0.4);

}
.container:hover .listingDetail{
  visibility: visible;
}
.listingDetail{
  color: white;
  background: rgba(0,0,0,0.4);
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border: 2px solid var(--color-bronze);
  visibility: hidden;
  padding-top: 5%;
  padding-bottom: 25%;
}
.listingDetail span {
  display: block;
}
.listingDetail p {
  text-align: center;
}
.listingDetail p.font-medium {
  margin-top:.5rem;
}
.listingDetail p svg {
  display: inline-block;
}
</style>
<script>
export default {
  name: "NewDevelopmentsBig",
  props: {
    price: String,
    img: String,
    location: String,
    title: String,
    grossArea: String,
    bedrooms: String,
    parking: String,
    dev_id: Number
  },
  data(){
    return{
      reloadKeyImage: 0
    }
  },
  created(){
  },
  methods:{
    // force the image to load
    errorImage(){
      this.reloadKeyImage++
    }
  },
  computed:{
    getLabel(){
      let priceLabel = this.$t('starting_price')

      if(this.price == this.$t('price_under_request'))
        priceLabel = ''

      return priceLabel
    },
    getDevId(){
      return this.dev_id
    }
  }
}
</script>