<template>
    <div class="bg-bggray w-screen" v-if="dataLoading == false">
        
    <transition 
        enter-active-class="animated fadeIn">
            <Cover
                id="cover_content" 
                />  
    </transition>

    
        <Collection 
            v-if="getCollectionPrivee.length != 0 && homepageReady"
            id="collection_content"
            :items="getCollectionPrivee"
        />
        
        
        
        
        <Listings
            v-if="homepageReady"
            @reload="reload" 
            id="listings_content" 
            :items="homepageListingItems"
            :listingsIndexShow="listingsIndexShow"
            :key="realoadingDiv" 
            :pagination="computedListingPageNumber"
        />

        <NewDevelopments
            v-if="homepageReady"
            id="newDevelopments_content"
            @reload="reload"
            :items="condominiumsItems"
            :listingsIndexShow="newDevelopmentsIndexShow"
            :key="newDevelopmentsKey" 
            :pagination="computedDevPageNumber"
        />
        

        <div class="lg:hidden">
            <ThisIsLAMobile 
                id="thisislamobile_content"
            />
        </div>
        
        <div class="hidden lg:block">
            <Services 
                id="services_content"/>
        </div>
        <!-- It needs to be greater then 2 because 2 are already by default 'beachfront' and 'vineyards' -->
        <div class="hidden lg:block">
            <Districts
                v-if="districtsItems.length > 2 && homepageReady"
                id="districts_content"
                :items="districtsItems"
                :vineyardInformation="vineyardInformation"
                :beachfrontInformation="beachfrontInformation"
                :propertiesList="listingsItems"
            />
        </div>

        <div class="lg:hidden">
            <DistrictsMobile
                v-if="districtsItems.length > 2 && homepageReady"
                id="districts_content"
                :items="districtsItems"
            />
        </div>
        
        <Contact 
            id="contact_content"/>
        
        <TheFooter 
            v-if="homepageReady"
            properties
            :items="homepageListingItems"
            @open-privacy-window="openPrivacyWindow"/>

        <Modal v-show="privacyModal" @close="closePrivacyModal">
            <!-- begin::modal body -->
            <template v-slot:body>
               <Privacy />
            </template>
            <!-- end::modal body -->
        </Modal>

    </div>
    
</template>
<script>
import Cover from "@/views/pages/Cover";
import Collection from "@/views/pages/Collection";
import ThisIsLAMobile from "@/views/pages/ThisIsLAMobile";
import NewDevelopments from "@/views/pages/NewDevelopments";
import Listings from "@/views/pages/Listings";
import Districts from "@/views/pages/Districts";
import DistrictsMobile from "@/views/pages/DistrictsMobile";
import Services from "@/views/pages/Services";
import Contact from "@/views/pages/Contact";
import TheFooter from '@/layout/footer/FooterExtended'
import Modal from '@/components/modal/Modal'
import Privacy from '@/views/pages/Privacy'


// Execution code
//import { Property } from '@/store/structs/properties'
import { PropertyHomePage } from '@/store/structs/properties'
import mixinProperty from '@/models/properties/PropertiesModel'


import { District } from '@/store/structs/districts'
import mixinDistrict from '@/models/districts/DistrictsModel'

import { Condominium } from '@/store/structs/condominium'
import mixinCondominium from '@/models/condominium/CondominiumsModel'

import homepageCaller from '@/models/homepage/HomepageModel' 



export default {
    name: "Home",
    mixins: [mixinProperty, mixinDistrict, mixinCondominium, homepageCaller],
    components: {
        Cover,
        Collection,
        ThisIsLAMobile,
        NewDevelopments,
        Listings,
        Districts,
        DistrictsMobile,
        Services,
        Contact,
        TheFooter,
        Modal,
        Privacy,
    },
    data: function () {
            return {
                dataLoading: true,
                propertiesLoading: false,
                districtsLoading: true,
                homepageReady: false,
                condominiumsLoading: true,
                realoadingDiv: 0,
                listingsIndexShow: [0, 6],
                newDevelopmentsIndexShow: [0, 4],
                newDevelopmentsKey: 0 + "A",
                collectionsKey: 0 + "B",
                privacyModal: false,
                developmentPage: 1,
                listingPage: 1,
                // If cookie true, it was accepted before
                vineyardInformation:{
                    id: 9998,
                    name:{
                        pt: 'Quinta de vinhos',
                        en: 'Vineyards'
                    },
                    description:{
                        pt: '',
                        en: ''
                    },
                    cover: "/img/vineyards/cover_HP.jpg",
                    cover2: "/img/vineyards/cover_HP.jpg",
                    cover3: "/img/vineyards/cover_HP.jpg",
                    kpis:{
                        population: null,
                        properties: null,
                        golf_club: null
                    },
                    districts_title1:{
                        pt: '',
                        en: '',
                    },
                    districts_title2:{
                        pt: '',
                        en: '',
                    },
                    districts1:{
                        pt: '',
                        en: '',
                    },
                    districts2:{
                        pt: '',
                        en: '',
                    },
                    districts3:{
                        pt: '',
                        en: '',
                    },
                    districts4:{
                        pt: '',
                        en: '',
                    },
                    districts5:{
                        pt: '',
                        en: '',
                    },
                    key_point1:{
                        pt: '',
                        en: '',
                    },
                    key_point2:{
                        pt: '',
                        en: '',
                    },
                    key_point3:{
                        pt: '',
                        en: '',
                    },
                    key_point4:{
                        pt: '',
                        en: '',
                    },
                    presents:{
                        pt: '',
                        en:''
                    },
                    sort_id: 9998
                },
                beachfrontInformation:{
                    id: 9999,
                    name:{
                        pt: 'Praia',
                        en: 'Beachfront'
                    },
                    description:{
                        pt: '',
                        en: ''
                    },
                    cover: "/img/beachfront/cover_HP.jpg",
                    cover2: "/img/beachfront/cover_HP.jpg",
                    cover3: "/img/beachfront/cover_HP.jpg",
                    kpis:{
                        population: null,
                        properties: null,
                        golf_club: null
                    },
                    districts_title1:{
                        pt: '',
                        en: '',
                    },
                    districts_title2:{
                        pt: '',
                        en: '',
                    },
                    districts1:{
                        pt: '',
                        en: '',
                    },
                    districts2:{
                        pt: '',
                        en: '',
                    },
                    districts3:{
                        pt: '',
                        en: '',
                    },
                    districts4:{
                        pt: '',
                        en: '',
                    },
                    districts5:{
                        pt: '',
                        en: '',
                    },
                    key_point1:{
                        pt: '',
                        en: '',
                    },
                    key_point2:{
                        pt: '',
                        en: '',
                    },
                    key_point3:{
                        pt: '',
                        en: '',
                    },
                    key_point4:{
                        pt: '',
                        en: '',
                    },
                    presents:{
                        pt: '',
                        en:''
                    },
                    sort_id: 9999
                }
            }
        },
    async created(){

        try{  

            this.emitHeader()

            await this.getHomepageData()
    
      
          
            this.homepageReady = true

            this.dataLoading = false
        }catch(error){
            console.log(error)
        }
        

        
        if(this.$route.params.chunk != null){
          this.$router.push({ hash: this.$route.params.chunk, params: { lang: this.$i18n.locale }})
        }

        if(this.$route.hash){
          requestAnimationFrame(() => {
            const element = document.querySelector(this.$route.hash);
            element.scrollIntoView();
          });
        }
    },
    destroyed () {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {      
        closePrivacyModal(){
            this.privacyModal = false
        },
        openPrivacyWindow(){
            let routeData = this.$router.resolve({name: 'privacy', params: { lang: this.$i18n.locale }});
            window.open(routeData.href, '_blank');
        },

        reload(data, local, newPage){
            if(local == "listings"){
                this.listingsIndexShow = data
                this.listingPage = newPage
                this.realoadingDiv += 1
            }
            if(local == "newDevelopments"){
                this.newDevelopmentsIndexShow = data
                this.developmentPage = newPage
                this.newDevelopmentsKey += 1
            } 
            if(local == "collections"){
                this.collectionsKey += 1
            }        
        },
        emitHeader(){
            this.$emit('show-header')
        }
    },
    computed: {
        computedListingPageNumber(){
            return this.listingPage
        },
        computedDevPageNumber(){
            return this.developmentPage
        },
        listingsItems() {
            let retrievedData = this.$store.state.homepageModule.homepagedata.allProperties
            
            return Object.keys(retrievedData).map(k =>
                new PropertyHomePage(retrievedData[k])
            )
        },
        homepageListingItems(){
            let retrievedData = this.$store.state.homepageModule.homepagedata.listings
           
            retrievedData.sort((a, b) => {
                return a.highlighted - b.highlighted
            })

            retrievedData.reverse()
            
            return Object.keys(retrievedData).map(k =>
                new PropertyHomePage(retrievedData[k])
            )
        },
        districtsItems(){
            let retrievedData = this.$store.state.homepageModule.homepagedata.districts.districts

            retrievedData.push(this.vineyardInformation)
            retrievedData.push(this.beachfrontInformation)

            retrievedData.sort((a, b) => a.sort_id - b.sort_id);

            return Object.keys(retrievedData).map(k =>
                new District(retrievedData[k])
            )
        },
        condominiumsItems(){            
            let retrievedData = this.$store.state.homepageModule.homepagedata.condominiums.condominiums
            let composedList = []
            
            retrievedData.forEach(element => {
                if(element.archived == 0)
                    composedList.push(element)
            });

            return Object.keys(composedList).map(k =>
                new Condominium(composedList[k])
            )
        },
        getCollectionPrivee(){
            let retrievedData = this.$store.state.homepageModule.homepagedata.collection
           
            let collection = []
            collection = Object.keys(retrievedData).map(k =>
                new PropertyHomePage(retrievedData[k])
            )
        
            
            let returnCollection = []
            collection.forEach(function(key){
                returnCollection.push(key)
            })
            
            return returnCollection 
        }
    }
};
</script>
