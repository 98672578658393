<template>
    <div class="max-h-frame rounded-full p-3 md:p-4 absolute cursor-pointer">
        <div class="absolute inset-0 w-full h-full text-center flex flex-col justify-center">
            <div class="self-center z-20 text-white font-black">
                <p class="uppercase font-blackMango tracking-wider text-sectionTitle md:text-lg">{{ district.name }}</p>
            </div>
            <div class="absolute bottom-10 z-20 inset-x-0 mx-auto text-white font-black" v-if="district.properties != null">
                <p class="uppercase font-blackMango tracking-wider md:text-md text-lg" v-if="false">{{ numberProperties }}</p>
                <p class="md:text-xs text-base font-light uppercase tracking-wider" v-if="false">{{ district.properties.name }}</p>
            </div>
        </div>
        <div class="bg-black opacity-20 inset-0 w-full h-full absolute rounded-full"></div>
            <img v-if="district.id == 9998 || district.id == 9999" class="object-center object-cover rounded-full w-full h-full" 
                :key="district.id" 
                v-lazy="district.cover"
            >
            <img v-else class="object-center object-cover rounded-full w-full h-full" 
                :key="district.id" 
                v-lazy="$mediaFile('districts', district.id , district.cover)"
            >
    </div>
</template>

<script>
    export default {
        name: "NextDistrict",
        props: {
            district: Object,
            numberProperties: Number,
            width: String,
            height: String
        },
    }
</script>