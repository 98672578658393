<template>
    <div class="absolute text-white bottom-6 py-4 w-3/5 mx-auto inset-x-0 glass-effect rounded-3xl  gap-4 items-center">
        <div class="pl-8 flex flex-col items-center justify-end">
            <inline-svg fill="white" class="w-3/4 h-auto" src="/svg/text/forSale.svg" />
            <router-link :to="{ name: 'listings', params: { lang: this.$i18n.locale }}">
            <button
                class="font-medium mt-4 uppercase text-xs tracking-button inline-flex items-center justify-center bg-bronze w-36 text-white p-2 px-6">
                <span>{{ $t('buttons.viewAll') }}</span>
            </button>
            </router-link>
        </div>
        <div class="flex relative items-center pr-8 pl-3 w-full">
            <div class="ml-4 w-1/3" v-for="dp in getTopProperties" :key="dp.id">
                <PropertyCardSmall
                  :id="dp.id"
                  :baths="new String(dp.kpis.baths)"
                  :rooms="new String(dp.kpis.beds)"
                  :garage="new String(dp.parking)"
                  :area="new String(dp.kpis.m2)"
                  :img="$mediaFile('properties', dp.id , dp.media.cover)"
                  :price="new String(dp.price)"
                  sizeImg="h-img-small-card" 
                  color="white"
                  :dev_id="dp.id"
                />
            </div>

            <!-- begin::navigation district -->
            <div class="absolute left-0 mt-auto h-full z-30 flex items-center">
                <button @click="decrementPosition">
                    <inline-svg class="transform rotate-180" width="1rem" height="1rem" fill="white"
                        src="/svg/arrow/arrow.svg" />
                </button>
            </div>
            <div class="absolute mt-auto right-2 h-full z-30  flex items-center">
                <button @click="incrementPosition">
                    <inline-svg width="1rem" height="1rem" fill="white" src="/svg/arrow/arrow.svg" />
                </button>
            </div>
            <!-- end::navigation district -->
        </div>
    </div>
</template>

<script>
import PropertyCardSmall from "@/components/listings/cards/PropertyCardSmall";

export default {
  name: "DistrictsProperties",
  components: {
    PropertyCardSmall
  },
  props: {
    district: null,
    propertiesList: null
  },
  data: function(){
      return {
        districtPropertiesNumber: 3,
        listPosition:{
            begin: 0,
            end: 3
        }
    }
  },

  created(){
      this.listPosition.begin = 0
      this.listPosition.end = 3
  },
  computed: {
        getTopProperties() {
            //let finalPropList = []
            //let districtName = this.district.name
            //this.propertiesList.forEach(function(key){
            //    if(key.information.location.district == districtName)
            //        finalPropList.push(key)
            //})
            //console.log("ESCREVE")
            //console.log(this.propertiesList.slice(this.listPosition.begin, this.listPosition.end))
            return this.propertiesList.slice(this.listPosition.begin, this.listPosition.end)
        }
  },
  methods:{
        goToFirstPage(){
            this.listPosition.begin = 0
            this.listPosition.end = 3
        },
        incrementPosition(){
            if(this.listPosition.end >= this.getTopProperties.length+1)
                return
            
            this.listPosition.begin += this.districtPropertiesNumber
            this.listPosition.end += this.districtPropertiesNumber
        },
        decrementPosition(){
            if(this.listPosition.begin == 0)
                return
                
            this.listPosition.begin -= this.districtPropertiesNumber
            this.listPosition.end -= this.districtPropertiesNumber
        }
  }
}
</script>