<template>
    <div class="w-screen mt-8 md:mt-16 lg:mt-24">
        <!-- begin::title section -->
        <div class="flex items-center flex-col px-5 md:px-0">
            <p class="md:text-sectionTitle text-lg w-full text-left md:text-center font-understock">{{ $t('homepage.newDevelopments.title') }}</p>
            <p class="text-baselg w-full text-left md:text-center font-blackMango tracking-wider md:w-2/3">
                {{ $t('homepage.newDevelopments.subtitle') }}</p>
            <!-- buttons goes to the listings page with the new developments filter selected - click.native for components -->
            <ButtonHomepage bgColor="bronze" class="md:flex hidden mt-6" :text="$t('buttons.viewAll')" @click="goToDevelopmentsEgo" />
        </div>
        <!-- end::title section -->
        <!-- begin::propeties desktop e tablet -->
        <div v-if="false" class="hidden lg:flex flex-col justify-center mt-6 md:w-full px-5 md:px-10 lg:px-0 lg:w-10/12 inset-x-0 mx-auto">
            <div class="relative">
                <transition-group
                    class="grid lg:grid-cols-4 py-10 px-14 lg:p-14 text-md bg-white mb-3 mt-4 rounded-2xl gap-4 lg:gap-8 gap-y-7 w-full"
                    mode="out-in" name="fade">
                <a
                    v-for="item in itemsList" 
                    :key="item.id+'IOP'" 
                    @click="generateLink(item.id)" 
                >
                    <NewDevelopmentsCard                         
                        :img="$mediaFile('condominiums', item.id , item.cover)"
                        :price="item.hide_price == 1 ? $t('price_under_request') : $currency(new String(item.starting_price))" 
                        :location="item.condo_where"
                        :title="$processLanguage(item.information.title)" 
                        :grossArea="item.from_gross_area + ' - ' + item.to_gross_area"    
                        :bedrooms="item.from_bedrooms + ' - ' + item.to_bedrooms"   
                        :parking="item.from_parking + ' - ' + item.to_parking"   
                        :dev_id="item.id"
                    />
                </a>
                </transition-group>
                <!-- begin::navication arrows-->
                <button class="absolute left-5 inset-y-0 z-40 my-auto"  @click="decrementListingIndexShow">
                    <inline-svg class="transform origin-center rotate-180" width="1.2rem" height="1.2rem"
                        src="/svg/arrow/arrowBall.svg" />
                </button>
                <button class="absolute right-5 inset-y-0 z-40 my-auto"  @click="incrementListingIndexShow">
                    <inline-svg class="transform origin-center rotate-0" width="1.2rem" height="1.2rem"
                        src="/svg/arrow/arrowBall.svg" />
                </button>
                <!-- end::navigation arrows -->
                <div v-show="viewMoreOverlay" class="absolute w-full h-full top-0 view-more-overlay z-30 flex items-center justify-center">
                    
                        <ButtonHomepage bgColor="bronze" class="md:flex hidden mt-6" :text="$t('buttons.viewAll')" @click="goToDevelopments" />
                    
                </div>
            </div>
            <!-- end::propeties -->
            <!-- begin::navigation -->
            <NavigationIndex :currentNumber="computedPagination" :totalNumber="Math.ceil(items.length/4)" color="bronze" />
        </div>
        <!-- end::navigation -->
        <!-- begin::propeties mobile -->
        <div class="lg:hidden mt-5 md:mt-8 pl-5 md:pl-10 w-screen block relative">
            <swiper :options="swiperOption" class="swiper" ref="swiperDevelopments" v-if="false">
                <swiper-slide v-for="item in mobileItemsList" :key="item.id+'POI'">
                    <a
                    @click="generateLink(item.id)" 
                    >
                    <NewDevelopmentsCard                         
                        :img="$mediaFile('condominiums', item.id , item.cover)"
                        :price="item.hide_price == 1 ? $t('price_under_request') : $currency(new String(item.starting_price))" 
                        :location="item.condo_where"
                        :title="$processLanguage(item.information.title)" 
                        :grossArea="item.from_gross_area + ' - ' + item.to_gross_area"    
                        :bedrooms="item.from_bedrooms + ' - ' + item.to_bedrooms"   
                        :parking="item.from_parking + ' - ' + item.to_parking"
                        :dev_id="item.id"
                    />
                    </a>
                </swiper-slide>
            </swiper>
            
            <ButtonHomepage bgColor="black" class="md:hidden block mt-5" :text="$t('buttons.viewAll')" @click="goToDevelopmentsEgo"/>
            
        </div>
        <!-- end::propeties mobile -->
    </div>
</template>

<style lang="scss" scoped>
  .swiper-slide {
      width: 70%;
      .swiper-slide:last-child {
          width: 100%;
      }
      /* tablet */
      @media (min-width: 768px) {
          width: 30%;
          .swiper-slide:last-child {
              width: 100%;
          }
      }
  }
  .view-more-overlay{
        background: rgba(255,255,255,0.7);
    }
</style>

<script>
    import NewDevelopmentsCard from "@/components/newDevelopments/NewDevelopmentCard.vue";
    import ButtonHomepage from "@/components/buttons/ButtonHomepage.vue";
    import NavigationIndex from "@/components/navigationIndex/NavigationIndex.vue";

    import {
        Swiper,
        SwiperSlide
    } from 'vue-awesome-swiper'
    import 'swiper/swiper-bundle.css'

    export default {
        name: "NewDevelopment",
        props: ['items', 'listingsIndexShow', 'pagination'],
        components: {
            NewDevelopmentsCard,
            Swiper,
            SwiperSlide,
            ButtonHomepage,
            NavigationIndex
        },
        data: function () {
            return {
                listings: null,
                newDevelopmentsListDesktop: 4,
                newDevelopmentsListMobile: 8,
                swiperOption: {
                    loop: false,
                    slidesPerView: 'auto',
                    spaceBetween: 15,
                },
                variableToIncrement: 4,
                myActualPage: 0,
                viewMoreOverlay: false
            }
        },
        created(){
            this.myActualPage = this.pagination

            if(this.myActualPage == Math.ceil(this.items.length/this.newDevelopmentsListDesktop)) {
                this.viewMoreOverlay = true;
                /*var that = this;
                setTimeout(function(){
                    that.viewMoreOverlay = true;
                },1000)*/
            }
        },
        computed: {
            itemsList(){
                return this.items.slice(this.listingsIndexShow[0], this.listingsIndexShow[1])
            },
            mobileItemsList(){
                return this.items
            },
            computedPagination(){
                return this.pagination
            }
        },
        methods:{
            goToDevelopmentsEgo(){
                let isEnglish = this.$i18n.locale == 'en'
                if(isEnglish){
                    window.location.href = this.$ego_redirecter() + '/en-gb/developments'
                }else{
                    window.location.href = this.$ego_redirecter() + '/empreendimentos'
                }
                
            },
            goToDevelopments(){
                localStorage.setItem("newDevelopments", 'true');
                this.$router.push({ name: 'listings', params: { lang: this.$i18n.locale, data: '0' }})
            },
            generateLink(id){
                this.$router.push({ name: 'development', params: { lang: this.$i18n.locale, slug: id }})
            },
            incrementListingIndexShow(){
                if(this.listingsIndexShow[1] >= this.items.length)
                    return this.viewMoreOverlay = true;

                this.myActualPage = this.myActualPage + 1

                this.listingsIndexShow[0] += this.variableToIncrement
                this.listingsIndexShow[1] += this.variableToIncrement
                    
                this.forceRender()
            },
            decrementListingIndexShow(){               
                if(this.listingsIndexShow[0] == 0)
                    return
                    
                this.myActualPage = this.myActualPage - 1

                this.listingsIndexShow[0] -= this.variableToIncrement
                this.listingsIndexShow[1] -= this.variableToIncrement
                this.forceRender()
            },
            forceRender(){
                this.$emit('reload', this.listingsIndexShow, "newDevelopments", this.myActualPage)
            }
        },
    };
</script>
